// esta es la pagina de gracias de contacto
import React from "react"
import Layout from "../components/layout"
import Img from "../assets/images/bgHeroBlog.jpg"
import { Helmet } from "react-helmet"
import Hero from "../components/hero"
import Block from '../components/blog/block'
import { DataBlog } from '../data/blog'
export default function Blog() {
  return (
    <>
      <Helmet>
        <title>BLOG</title>
        <meta
          name="description"
          content="este es el BLOG"
        ></meta>
      </Helmet>
      <Layout>
        <Hero img={Img} title="BLOG"></Hero>
        <Block  tipo='blog' data={DataBlog}></Block>
      </Layout>
    </>
  )
}
