import img01 from "../assets/images/blog/img01.jpg"
import img02 from "../assets/images/blog/img02.jpg"
import img03 from "../assets/images/blog/img03.jpg"
import img04 from "../assets/images/blog/img04.jpg"
import img05 from "../assets/images/blog/img05.jpg"
import img06 from "../assets/images/blog/img06.jpg"
import img07 from "../assets/images/blog/img07.jpg"
export const DataBlog = [
  {
    title: `Fábrica de carrocerías líder en el sur del Perú: Intramet, la historia`,
    text: `El sector carrocero de Arequipa funcionó por generaciones acumulando experiencia en carrocerías adaptables para las carreteras peruanas. Existían perfiles admirables de carroceros independientes tales como lo fue Agripino Coyuri. `,
    img: img01,
    url:`fabrica-de-carrocerias-lider-en-el-sur-del-peru`
  },
  {
    title: `Inicio de una carrera en carrocerías `,
    text: `Todo comenzó con Agripino Coyuri, quien se inició a los 17 tiernos años en el mundo de las carrocerías para el transporte de pasajeros. En el año 1967 Su primer centro de trabajó fue la empresa “San Cristóbal Del Sur” donde desempeñando el cargo de ayudante.`,
    img: img02,
    url:`inicio-de-una-carrera-en-carrocerias`
  },
  {
    title: `Consolidación de conocimiento y liderazgo`,
    text: `En los años 90 Agripino Coyuri consolida su alto grado de experiencia en la fabricación de carrocerías. Se convierte en Jefe de producción y pieza fundamental en la fundación de Intramet S.A. `,
    img: img03,
    url:`consolidacion-de-conocimiento-y-liderazgo`
  },
  {
    title: `Etapa de emprendimiento`,
    text: `Pasar de técnico a jefe y finalmente animarse a emprender fue un camino que se fue dando de forma natural.  `,
    img: img04,
    url:`etapa-de-emprendimiento`
  },
  {
    title: `Inicio de la Fábrica de Carrocerías líder del sur del Perú `,
    frase:`“Para ser la mejor empresa del sur del Perú no solo tenían que ser los mejores carroceros, tenían que adquirir visión empresarial”`,
    text: `El 17 de Julio del 2006, de forma estratégica y aprovechando la coyuntura en el desarrollo y crecimiento del sector, se funda la empresa “INTRAMET S.R.L.” en el rubro de reparación, mantenimiento y fabricación de carrocerías sobre chasis de las marcas más reconocidas tales como: SCANIA y MERCEDES BENZ.`,
    img: img05,
    url:`inicio-de-la-fabrica-de-carrocerias`
  },
  {
    title: `Etapa de evolución, tiempo de reinventarse`,
    text: `Con el paso de los años cambiaron las expectativas del mercado y no bastaba tener únicamente un vehículo de buena calidad, tenía que tener un diseño tan atractivo como los vehículos importados. `,
    img: img06,
    url:`etapa-de-evolucion`
  },
  {
    title: `¿Qué es lo que trae el futuro para Intramet?`,
    text: `Somos una empresa que lleva en su ADN el emprendimiento y excelencia.`,
    img: img07,
    url:`que-es-lo-que-trae-el-futuro-para-intramet`
  }

]